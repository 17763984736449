body {
  margin: 0;
  font-family: 'sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000 !important;
  background-color: #fff !important;
}

.nav-link {
  color: #000 !important;
}

p {
  font-size: 24px;
}

@media (max-width: 600px) {
}

@media (min-width: 600px) {
}
